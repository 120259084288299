import _ from 'lodash';


export const ImportUtil = {

    trimWhiteSpaces: (obj) => {
        let trimedObj = { ...obj };
        let keys = Object.keys(trimedObj);

        _.forEach(keys, key => {
            let value = trimedObj[key];
            if (value && value.length > 0 && !Array.isArray(value)) {
                value = value.trim();
            }
            trimedObj[key] = value;
        });

        return trimedObj;
    },

    hasBlankColumnName: (obj) => {
        let keys = Object.keys(obj);
        for (const key of keys) {
            if (key.trim().length === 0) {
                return true;
            }
        }
        return false;
    },

    getCollectionByKey: (values, key) => {
        return _.keyBy(Object.values(values), key);
    },

    getConnectionsObj: (connections) => {
        let connectionsObj = {};
        let connectionsArr = Object.values(connections);
        connectionsArr.forEach((connection) => {
            let sourceCompany = connection.company.name;
            let destinationCompany = connection.connectedCompany.name;

            if (!connectionsObj[sourceCompany]) {
                connectionsObj[sourceCompany] = [];
            }
            connectionsObj[sourceCompany].push(destinationCompany);

            if (connection.connectedCompany.isActive === 'false') {
                if (!connectionsObj[destinationCompany]) {
                    connectionsObj[destinationCompany] = [];
                }
                connectionsObj[destinationCompany].push(sourceCompany);
            }
        });
        return connectionsObj;
    },

    getAssetType: (assetTypesObj, name) => {
        let assetTypeObj = {};

        assetTypeObj = _.find(assetTypesObj, o => {
            return o.name === name;
        });

        if (!assetTypeObj) {
            assetTypeObj = {};
        }

        return assetTypeObj;
    },

    getTypes: (types) => {
        let typesArr = [];
        typesArr = _.filter(_.map(types, 'value'), o => {
            return o !== null;
        });
        return typesArr;
    },

    isValidCondition: (assetTypeObj, condition) => {
        let isValid = false;
        let conditions = !_.isEmpty(assetTypeObj) ? assetTypeObj.conditions : [];
        for (let i = 0; i < conditions.length; i++) {
            if (conditions[i].condition === condition) {
                isValid = true;
                break;
            }
        }
        return isValid;
    },

    isValidCategory: (assetTypeObj, category) => {
        let isValid = false;
        let categories = !_.isEmpty(assetTypeObj) ? assetTypeObj.categories : [];
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].name === category) {
                isValid = true;
                break;
            }
        }
        return isValid;
    },

    /**
     * Date Format: MM-DD-YYYY or MM/DD/YYYY
     */
    isValidDate: (dateStr) => {
        return /^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/.test(dateStr)
            || /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(dateStr);
    },

    isValidNumber: (value) => {
        return /^[0-9.]*$/.test(value);
    },

    hasDuplicates(array, key) {
        let values = _.map(array, key);
        let uniqValues = _.uniq(values);
        return values.length > uniqValues.length;
    },

    getDuplicates(array, key) {
        let duplicates = [];
        let arrayPerKey = _.groupBy(array, key);

        _.forEach(arrayPerKey, (value, _key) => {
            if (value.length > 1) {
                duplicates.push(_key);
            }
        });

        return duplicates;
    },

    isValidAreaRadius(areaRadius) {
        return areaRadius >= 10 && areaRadius <= 1000;
    }
};
