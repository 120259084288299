import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    importAssets(param) {
        let url = `${this.baseUrl}/importAssets`;
        return axios.post(url, param);
    },

    importDispatch(param) {
        let url = `${this.baseUrl}/importDispatch`;
        return axios.post(url, param);
    },

    importCompanies(param) {
        let url = `${this.baseUrl}/importCompanies`;
        return axios.post(url, param);
    },

    importStorageLocations(param) {
        let url = `${this.baseUrl}/importStorageLocations`;
        return axios.post(url, param);
    },

    importTransportations(param) {
        let url = `${this.baseUrl}/importTransportations`;
        return axios.post(url, param);
    },

    importUsers(param) {
        let url = `${this.baseUrl}/importUsers`;
        return axios.post(url, param);
    }
}
